import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {PageHeader} from 'antd';
import RichTextPreview from '../../Components/RichTextPreview';
import {serviceCategoryArr, newsCategoryArr} from '../../Category';

function isShowCreatedText(article) {
  const isNotShowCreatedLabels = [...newsCategoryArr].filter(
    (i) => i !== '院內公告',
  );

  if (Array.isArray(article.label) && article.label.length > 0) {
    const isShowCreatedText =
      article.label.findIndex((l) => {
        return isNotShowCreatedLabels.includes(l);
      }) === -1;

    return isShowCreatedText;
  }

  return true;
}

function ArticleDetail(props) {
  const prefixPath = '/article';
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const [article, setArticle] = React.useState(null);
  const params = qs.parse(props.location.search);
  const {id} = params;
  const mobile = dimension.innerWidth < 720;
  const [bannerDimension, setBannerDimension] = React.useState(null);

  let pathname = '';
  if (typeof window !== 'undefined') {
    pathname = window.location.pathname;
  }
  console.log('pathname artucle detail', pathname);
  /*
  detail: /news -> list: /articles 最新消息 
  detail: /member -> list: /team 醫生團隊
  detail: /article -> list: /witness 美麗見證
  detail: /service -> list: /services 服務項目
  detail: /about-post -> list: /about 關於我們
  */

  React.useEffect(() => {
    async function fetchData() {
      try {
        actions.setLoading(true);
        setArticle(await actions.clientFetchArticleById(id));
      } catch (ex) {
      } finally {
        actions.setLoading(false);
      }
    }

    fetchData();
  }, [actions, id]);

  // React.useEffect(() => {
  //   const script = document.createElement('script');
  //   window.instgrm.Embeds.process();
  //   script.src = 'http://platform.instagram.com/en_US/embeds.js';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  let backToListPage = React.useMemo(() => {
    if (pathname === '/news') {
      return '/articles';
    } else if (pathname === '/member') {
      return '/team';
    } else if (pathname === '/article') {
      return '/witness';
    } else if (pathname === '/service') {
      const cat = article?.label[1];
      const element = serviceCategoryArr.find((item) => item.name === cat);
      return element ? element.path : '/services';
    } else if (pathname === '/about-post') {
      return '/about';
    }
  }, [pathname, article?.label]);
  console.log('backToListPage000000000', backToListPage);
  return (
    <Wrapper>
      {/* {renderCustomSection('A')} */}

      <div className="section">
        {/* {renderCustomSection('B')} */}

        <div
          style={{display: 'flex', flexDirection: mobile ? 'column' : 'row'}}>
          <div style={{flex: 1}}>
            <PageHeader
              title={' '}
              onBack={() => navigate(backToListPage)}
              style={{padding: 0, marginBottom: 50}}
            />
            {article && (
              <>
                <h2 className="title">{article.title || '無標題'}</h2>
                {pathname === '/news' && isShowCreatedText(article) && (
                  <p className="grid-item-date" style={{marginTop: 10}}>
                    {new Date(article.created).toLocaleDateString('en-ZA')}
                  </p>
                )}
                <RichTextPreview content={article.content} />
              </>
            )}
          </div>
          {/* {renderCustomSection('C')} */}
        </div>

        {/* {renderCustomSection('D')} */}
      </div>

      {/* {renderCustomSection('E')} */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .grid-item-date {
    color: #666666;
    font-size: 13px;
    letter-spacing: 2px;
    margin: 25px 0px;
  }
`;

export default ArticleDetail;
